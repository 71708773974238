import PropTypes from 'prop-types'
import React, {useReducer, createContext, useContext} from 'react'
import CreditReducer from '../reducers/CreditReducer'

export const CreditStateContext = createContext()
export const CreditDispatchContext = createContext()

const initialState = {
  applicantType: 'Individual',
  step: 0,
  currentStep: 1,
  totalSteps: 6,
  applicant: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    dob: '',
    email: '',
    phone1: '',
    phoneType1: 'mobile',
    ssn: '',
    driverLicenseNumber: '',
    driverLicenseState: '',
    maritalStatus: '',
    spouse: {
      firstName: '',
      lastName: '',
      addressSameAsApplicant: false,
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
    },
    residences: {},
    employers: {},
    otherIncome: {},
    references: {},
    coBuyerPresent: false,
    coBuyers: {
      coBuyer: {
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        dob: '',
        email: '',
        phone1: '',
        phoneType1: 'home',
        ssn: '',
        driverLicenseNumber: '',
        driverLicenseState: '',
        maritalStatus: '',
        relationship: '',
        spouse: {
          firstName: '',
          lastName: '',
          addressSameAsApplicant: false,
          address: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
          },
        },
        residences: {},
        employers: {},
        otherIncome: {},
        references: {},
      },
    },
    buyerSigned: false,
    buyerChecked: false,
    coBuyerSigned: false,
    coBuyerChecked: false,
  },
}

export const CreditContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(CreditReducer, initialState)
  return (
    <CreditStateContext.Provider value={state}>
      <CreditDispatchContext.Provider value={dispatch}>{children}</CreditDispatchContext.Provider>
    </CreditStateContext.Provider>
  )
}

export const useCreditStateContext = () => useContext(CreditStateContext)
export const useCreditDispatchContext = () => useContext(CreditDispatchContext)

CreditContextProvider.propTypes = {
  children: PropTypes.element,
}
