import {format} from 'date-fns'

export const formatToInternationalNumber = (phoneNumber: string) => {
  if (phoneNumber.match(/^\+\d/)) {
    return phoneNumber
  }

  const cleaned = `${phoneNumber}`.replace(/\D/g, '')

  return `+1${cleaned}` // If they didn't specify a + code originally, we'll assume it's a US based number
}

export const displayPhoneNumber = ({phoneNumber}: {phoneNumber?: string}) => {
  if (!phoneNumber) {
    return ''
  }

  let newPhoneNumber = phoneNumber

  if (newPhoneNumber.length === 12) {
    newPhoneNumber = newPhoneNumber.substring(2, 12)
  }

  if (newPhoneNumber.length === 10) {
    const areaCode = newPhoneNumber.substring(0, 3)
    const firstThree = newPhoneNumber.substring(3, 6)
    const lastFour = newPhoneNumber.substring(6, 10)

    return `(${areaCode}) ${firstThree}-${lastFour}`
  }

  return newPhoneNumber
}

export const plurality = (count: number) => (count !== 1 ? 's' : '')

export const formatDate = (date: number | string | Date, dateformat = 'yyyy-MM-dd') => {
  return date !== undefined && date ? format(new Date(date), dateformat) : ''
}
