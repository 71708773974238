import {useEffect, useState} from 'react'
import generateUuid from 'Src/utilities/generateUuid'

export const SESSION_ID_STORAGE_KEY = 'session-id'

export const generateNewSessionId = () => {
  const newSessionId = generateUuid()
  localStorage.setItem(SESSION_ID_STORAGE_KEY, newSessionId)

  return newSessionId
}

export const getSessionId = () => localStorage.getItem(SESSION_ID_STORAGE_KEY)

export const useSessionId = () => {
  const [sessionId, setSessionId] = useState(null)

  useEffect(() => {
    const storedId = getSessionId()

    if (storedId) {
      setSessionId(storedId)

      return
    }

    setSessionId(generateNewSessionId())

    window.onunload = () => {
      localStorage.removeItem(SESSION_ID_STORAGE_KEY)
    }
  }, [])

  return {sessionId}
}
