export const LOADING_STATE = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  FAILED: 'FAILED',
}

export const initialNavState = {
  page: '/',
  showSlideOut: true,
  loading: LOADING_STATE.LOADING,
}

export default function NavReducer(state, action) {
  switch (action.type) {
    case 'closeSlideOut':
      return {
        ...state,
        showSlideOut: false,
      }
    case 'openSlideOut':
      return {
        ...state,
        showSlideOut: true,
        page: action?.payload?.page,
      }
    case 'setLoading':
      return {
        ...state,
        loading: action.payload,
      }
    // TODO: Remove this. Currently still used in RegistrationActions.jsx
    case 'showRegistration':
      return {
        ...state,
        showRegistration: true,
      }
    case 'clearAfterRegistration':
      return {
        ...state,
        page: initialNavState.page,
      }
    case 'navigateAfterRegistration':
      return {
        ...state,
        page: action.payload.page,
      }
    default:
      throw new Error(`No matching action defined in NavReducer (${action.type})`)
  }
}
