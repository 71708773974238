export default function ProgressReducer(state, {type, payload}) {
  switch (type) {
    case 'update':
      return {
        ...state,
        ...payload,
      }
    default:
      throw new Error()
  }
}
