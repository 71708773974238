export const initialState = {
  sendEmail: false,
  sendEmailAfterRegistration: false,
  showAlert: false,
  showErrorAlert: false,
}

export default function EmailQuoteReducer(state, {type, payload}) {
  switch (type) {
    case 'setSendEmail':
      return {
        ...state,
        sendEmail: payload,
      }
    case 'setSendEmailAfterRegistration':
      return {
        ...state,
        sendEmailAfterRegistration: payload,
      }
    case 'setShowAlert':
      return {
        ...state,
        showAlert: payload,
      }
    case 'setShowErrorAlert':
      return {
        ...state,
        showErrorAlert: payload,
      }
    default:
      throw new Error()
  }
}
