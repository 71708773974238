// Source: https://stackoverflow.com/a/45287523/1694854
const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key

    return {[newKey]: obj[key]}
  })

  return Object.assign({}, ...keyValues)
}

/**
 * "normalizeMetadataKeys" accepts an object containing keys {make, model, year} and
 * returns an object with those keys renamed to {makeName, modelName, yearId}.
 */
export const normalizeMetadataKeys = (object) => {
  return renameKeys({...object}, {make: 'makeName', model: 'modelName', year: 'yearId'})
}
