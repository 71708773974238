import {useReducer} from 'react'

const initialState = {
  cityList: [], // city list formatted from endpoint responseA
  cityListOptions: [], // city list in a format for dropdowns
  cityListZipCode: null,
  cityListLoading: false,
  cityListLoadingError: '',
  newLead: true,
  skipMatchingLead: false,
  foundLeads: [],
}

const mapCityListOptions = (cityListResponseData) => {
  const oneInCity = cityListResponseData.some((x) => x?.attributes?.inCity === true)

  // if there is only one option, do not append
  // if there is more than one option and at least one with an inCity of true, append, else, do not
  return cityListResponseData.map((i) => ({
    label: `${i.attributes.city}, ${i.attributes.county}${
      (cityListResponseData.length > 1 &&
        oneInCity &&
        (i.attributes.inCity ? ' (IN CITY LIMITS)' : ' (NOT IN CITY LIMITS)')) ||
      ''
    }`,
    value: {
      id: i.id,
      inCity: !!i.attributes.inCity,
      city: i.attributes.city,
      county: i.attributes.county,
      state: i.attributes.state,
    },
  }))
}

const RegistrationReducer = (state, action) => {
  switch (action.type) {
    case 'setFoundLeads':
      return {
        ...state,
        foundLeads: action.payload,
      }
    case 'setSkipMatchingLead':
      return {
        ...state,
        skipMatchingLead: action.payload,
      }
    case 'setNewLead':
      return {
        ...state,
        newLead: action.payload,
      }
    case 'setCityList':
      return {
        ...state,
        cityList: action.payload,
        cityListOptions: mapCityListOptions(action.payload),
      }
    case 'setCityListZipCode':
      return {
        ...state,
        cityListZipCode: action.payload,
      }

    case 'setCityListLoading':
      return {
        ...state,
        cityListLoading: action.payload,
      }
    case 'setCityListLoadingError':
      return {
        ...state,
        cityListLoadingError: action.payload,
      }
    case 'reset':
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export const useRegistrationReducer = () => useReducer(RegistrationReducer, initialState)
