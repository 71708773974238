import {trans} from './Helpers'

export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n)

export const isNumericAndNonNegative = (n) => isNumeric(n) && parseFloat(n) >= 0

export const numberToUSD = (number) => {
  const numericNumber = isNumeric(number) ? number : 0

  return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
    .format(numericNumber)
    .replace(/(\.|,)00$/g, '')
}

export const formatMileage = (mileage) => mileage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
export const addThousandsSeparator = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const addTermMonthsYears = (months) => {
  const showYears = !(months % 12)
  const years = showYears ? months / 12 : 0

  return `${months} ${trans(months === 1 ? 'month' : 'months')}${
    showYears ? ` (${years} ${trans(years === 1 ? 'year' : 'years')})` : ''
  }`
}

export const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '')

export const formatValue = (value, asNegative = false) => {
  const prefix = asNegative ? '–$' : '$'

  return `${prefix}${addThousandsSeparator(value.toFixed(2))}`
}

export const deductibleFormatter = (value) => {
  const prefix = '$'
  const deductibleType = value.includes('Disappearing') ? 'Disappearing' : 'Standard'

  return `${prefix}${parseFloat(value).toFixed(2)} ${deductibleType}`
}
