import React from 'react'
import PropTypes from 'prop-types'
import {TradeContextProvider} from './contexts/TradeContext'
import {CustomerContextProvider} from './contexts/CustomerContext'
import {NavContextProvider} from './contexts/NavContext'
import {DealContextProvider} from './contexts/DealContext'
import {FrameContextProvider} from './contexts/FrameContext'
import {CreditContextProvider} from './contexts/CreditAppContext'
import {ProgressContextProvider} from './contexts/ProgressContext'
import {RegistrationContextProvider} from './contexts/RegistrationContext'
import {EmailQuoteContextProvider} from './contexts/EmailQuoteContext'
import {EmailShareQuoteContextProvider} from './contexts/EmailShareQuoteContext'
import {ProductContextProvider} from './contexts/ProductContext'
import {ReactQueryProvider} from './utilities/ReactQueryProvider'

const contextProviders = [
  ReactQueryProvider,
  NavContextProvider,
  CustomerContextProvider,
  TradeContextProvider,
  DealContextProvider,
  RegistrationContextProvider,
  FrameContextProvider,
  CreditContextProvider,
  ProgressContextProvider,
  EmailQuoteContextProvider,
  ProductContextProvider,
  EmailShareQuoteContextProvider,
]

const ComposeProviders = ({Providers, children}) =>
  Providers.reverse().reduce((acc, Provider) => <Provider>{acc}</Provider>, children)

const RootContextProvider = ({children}) => (
  <ComposeProviders Providers={contextProviders}>{children}</ComposeProviders>
)

RootContextProvider.propTypes = {
  children: PropTypes.node,
}

export default RootContextProvider
