export default function CreditReducer(state, {type, payload}) {
  switch (type) {
    case 'update':
      return {
        ...state,
        ...payload,
      }
    case 'applicantData':
      return {
        ...state.applicant,
        applicant: {
          firstName: payload.firstName,
          middleName: payload.middleName,
          lastName: payload.lastName,
          dob: payload.dob,
          email: payload.email,
          phone1: payload.phone1,
          phoneType1: payload.phoneType1,
          ssn: payload.ssn,
          driverLicenseNumber: payload.driverLicenseNumber,
          driverLicenseState: payload.driverLicenseState.value,
          maritalStatus: payload.maritalStatus.label,
        },
      }
    default:
      throw new Error()
  }
}
