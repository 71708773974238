import {useHistory} from 'react-router'
import {useShiftEvents} from '../../utilities/useShiftEvents'
import {useCustomerStateContext} from '../../contexts/CustomerContext'
import {useDealStateContext} from '../../contexts/DealContext'
import useConfig from '../../utilities/useConfig'
import {useAxios} from '../../utilities/useAxios'
import {EVENTS} from './constants'

export const useAnalytics = () => {
  const history = useHistory()

  const {customerData} = useCustomerStateContext()
  const dealState = useDealStateContext()
  const {analytics, vehicle} = useConfig()
  const [instance] = useAxios()
  const {trackGAEventAsShiftEvent} = useShiftEvents()

  const {dealType} = dealState || {}

  const urlToUnderscore = (url) => {
    if (!url || !url.pathname) {
      return 'vdp'
    }

    if (url.pathname === '/') {
      return 'desking'
    }

    return url.pathname.substring(1).replace('/', '_')
  }

  const buildEventName = (event) => {
    return `a2z.${event}`
  }

  const trackEvent = (event, action, actionDetail, customerId, params = {}) => {
    const eventData = {
      action,
      actionDetail,
      location: urlToUnderscore(history?.location),
    }

    trackGAEventAsShiftEvent({...eventData})

    // we should allow for null customer ids to track events on pages where the user is unauthenticated
    if (
      !window.dataLayer ||
      !analytics?.measurementIds ||
      // (!customerData?.id && !customerId) ||
      !event?.name
    ) {
      return
    }

    const clientUuid = instance?.defaults?.headers['client-uuid'] || 'unknown'
    const dealershipId = instance?.defaults?.headers['dealership-id'] || 'unknown'

    analytics.measurementIds.forEach((measurementId) => {
      // Default behavior merges collections preventing overwriting or removing
      // elements. This resets the array property if it exists which allows fresh
      // data to be written in the following push.
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          window.dataLayer.push({[key]: undefined})
        }
      })

      window.dataLayer.push({
        event: buildEventName(event.name),
        customerId: customerData?.id || customerId,
        measurementId,
        dealType,
        vehicleType: vehicle?.type?.toLowerCase(),
        rooftop: `Client ${clientUuid} - Dealership ${dealershipId}`,
        ...eventData,
        ...params,
      })
    })
  }

  return {
    trackEvent,
    events: EVENTS,
  }
}
