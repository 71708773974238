import React, {useState, createContext, useContext} from 'react'
import PropTypes from 'prop-types'
import {normalizeMetadataKeys} from '../components/TradePartners/TradePending/Helpers'

export const TradeContext = createContext(null)

const TradeContextProvider = ({children}) => {
  const [TradeVehicleYearId, setTradeVehicleYearId] = useState(null)
  const [TradeVehicleMakeId, setTradeVehicleMakeId] = useState(null)
  const [TradeVehicleModelId, setTradeVehicleModelId] = useState(null)
  const [TradeVehicleTrimId, setTradeVehicleTrimId] = useState(null)
  const [TradeVehicleId, setTradeVehicleId] = useState(null)
  const [TradeVehicleCondition, setTradeVehicleCondition] = useState(null)
  const [TradeVehicleMileage, setTradeVehicleMileage] = useState(null)
  const [TradeVehicleValue, setTradeVehicleValue] = useState(0)
  const [TradeVehicleMetadata, setTradeVehicleMetadata] = useState({})
  const [stillOwePayments, setStillOwePayments] = useState(false)
  const [estimatedPayoffAmount, setEstimatedPayoffAmount] = useState(0)
  const [totalTradeAmount, setTotalTradeAmount] = useState(0)
  const [dropDowns, setDropDowns] = useState({})
  const [hasUnsavedTradeData, setHasUnsavedTradeData] = useState(false)

  const resetTrade = () => {
    setTradeVehicleYearId(null)
    setTradeVehicleMakeId(null)
    setTradeVehicleModelId(null)
    setTradeVehicleTrimId(null)
    setTradeVehicleId(null)
    setTradeVehicleCondition(null)
    setTradeVehicleMileage(null)
    setTradeVehicleValue(0)
    setTradeVehicleMetadata({})
    setStillOwePayments(false)
    setEstimatedPayoffAmount(0)
    setTotalTradeAmount(0)
    setDropDowns({})
  }

  const setNormalizedTradeMetadata = (payload) => {
    setTradeVehicleMetadata(normalizeMetadataKeys({...TradeVehicleMetadata, ...payload}))
  }

  return (
    <TradeContext.Provider
      value={{
        TradeVehicleYearId,
        setTradeVehicleYearId,
        TradeVehicleMakeId,
        setTradeVehicleMakeId,
        TradeVehicleModelId,
        setTradeVehicleModelId,
        TradeVehicleTrimId,
        setTradeVehicleTrimId,
        TradeVehicleId,
        setTradeVehicleId,
        TradeVehicleCondition,
        setTradeVehicleCondition,
        TradeVehicleMileage,
        setTradeVehicleMileage,
        TradeVehicleValue,
        setTradeVehicleValue,
        TradeVehicleMetadata,
        setTradeVehicleMetadata: setNormalizedTradeMetadata,
        stillOwePayments,
        setStillOwePayments,
        estimatedPayoffAmount,
        setEstimatedPayoffAmount,
        totalTradeAmount,
        setTotalTradeAmount,
        dropDowns,
        setDropDowns,
        resetTrade,
        hasUnsavedTradeData,
        setHasUnsavedTradeData,
      }}
    >
      {children}
    </TradeContext.Provider>
  )
}

/**
 * @returns {{estimatedPayoffAmount, TradeVehicleValue, TradeVehicleMileage, TradeVehicleCondition, TradeVehicleMetadata}}
 */
const useTradeContext = () => useContext(TradeContext)

export {TradeContextProvider, useTradeContext}

TradeContextProvider.propTypes = {
  children: PropTypes.element,
}
