const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const stringifyFilters = (filters?: Record<string, string>) => {
  const entries = Object.entries(filters || {})

  if (!entries.length) {
    return ''
  }

  return `?${entries.map(([key, value]) => `filter[${camelToSnakeCase(key)}]=${value}`).join('&')}`
}

export const stringifyIncludes = (includes?: string[]) => {
  if (!includes?.length) {
    return ''
  }

  return `?include=${includes.join(',')}`
}

export const validateEmail = (email: string, required: boolean) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  if (!email && required) {
    return 'Email is a required field.'
  }

  if (email && !emailRegex.test(email)) {
    return 'A valid email address is required.'
  }

  return ''
}
