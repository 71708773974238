import React, {createContext, useContext, useReducer} from 'react'
import PropTypes from 'prop-types'
import CustomerReducer, {customerInitialState} from '../reducers/CustomerReducer'

export const ECodeReturnTo = {
  SEND_CODE: 'send_code',
  NEW_LEAD_CONFIRMATION: 'new_lead_confirmation',
}

export const CustomerStateContext = createContext(customerInitialState)
export const CustomerDispatchContext = createContext()

export const CustomerContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(CustomerReducer, customerInitialState)

  return (
    <CustomerStateContext.Provider value={state}>
      <CustomerDispatchContext.Provider value={dispatch}>
        {children}
      </CustomerDispatchContext.Provider>
    </CustomerStateContext.Provider>
  )
}

export const useCustomerStateContext = () => useContext(CustomerStateContext)
export const useCustomerDispatchContext = () => useContext(CustomerDispatchContext)

CustomerContextProvider.propTypes = {
  children: PropTypes.element,
}
