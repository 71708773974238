import PropTypes from 'prop-types'
import React, {useReducer, createContext, useContext} from 'react'
import ProgressReducer from '../reducers/ProgressReducer'

export const ProgressStateContext = createContext()
export const ProgressDispatchContext = createContext()

export const PROGRESS_STATE = {
  complete: 'complete',
  started: 'started',
  untouched: 'untouched',
}

export const PROGRESS_STEP = {
  payment: 'payment',
  trade: 'trade',
  products: 'products',
  credit: 'credit',
  finalize: 'finalize',
  uploads: 'uploads',
  documentUpload: 'document-upload',
}

export const initialState = {
  payment: PROGRESS_STATE.started,
  trade: PROGRESS_STATE.untouched,
  products: {
    cash: PROGRESS_STATE.untouched,
    finance: PROGRESS_STATE.untouched,
    lease: PROGRESS_STATE.untouched,
  },
  credit: PROGRESS_STATE.untouched,
  finalize: PROGRESS_STATE.untouched,
  uploads: PROGRESS_STATE.untouched,
}

export const ProgressContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(ProgressReducer, initialState)

  return (
    <ProgressStateContext.Provider value={state}>
      <ProgressDispatchContext.Provider value={dispatch}>
        {children}
      </ProgressDispatchContext.Provider>
    </ProgressStateContext.Provider>
  )
}

export const useProgressStateContext = () => useContext(ProgressStateContext)
export const useProgressDispatchContext = () => useContext(ProgressDispatchContext)

ProgressContextProvider.propTypes = {
  children: PropTypes.element,
}
