import {useEffect, useState} from 'react'

export const TOKEN_STATE = {
  LOADING: 'LOADING',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  AUTHENTICATED: 'AUTHENTICATED',
  EXPIRED: 'EXPIRED',
}

export const useToken = () => {
  const [tokenState, setTokenState] = useState(TOKEN_STATE.LOADING)
  const isBrowser = typeof window !== 'undefined'
  const setTokenStatus = (previousStatus) => {
    const exists = isBrowser && document.cookie.indexOf('pseudo-auth-token=') !== -1
    let currentStatus = ''
    if (previousStatus === TOKEN_STATE.AUTHENTICATED && !exists) {
      currentStatus = TOKEN_STATE.EXPIRED
    } else {
      currentStatus = exists ? TOKEN_STATE.AUTHENTICATED : TOKEN_STATE.UNAUTHENTICATED
    }

    setTokenState(currentStatus)
  }

  useEffect(() => {
    let timeout
    function checkStatus() {
      timeout = setTimeout(() => {
        setTokenStatus(tokenState)
        checkStatus()
      }, 1000)
    }

    checkStatus()
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return {
    tokenState,
    setTokenState,
  }
}
