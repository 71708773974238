/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from 'react'
import {MemoryRouter} from 'react-router'
import RootContextProvider from './src/RootContextProvider'

export const wrapRootElement = ({element}) => (
  <MemoryRouter>
    <RootContextProvider>{element}</RootContextProvider>
  </MemoryRouter>
)
