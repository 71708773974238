import {useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router'
import {TRANSITION_DURATION} from 'Src/components/SlideOut'
import {CTA_TYPES} from 'Src/constants'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useNavDispatchContext, useNavStateContext} from 'Src/contexts/NavContext'
import useConfig from 'Src/utilities/useConfig'
import useEventListener from 'Src/utilities/useEventListener'

export const WINDOW_IFRAME_OPEN_MESSAGE = 'digitalretail-iframe-open'
export const WINDOW_IFRAME_CLOSE_MESSAGE = 'digitalretail-iframe-close'

const getPageFromCtaType = ({ctaType}: {ctaType: TCtaType | undefined}) => {
  switch (ctaType) {
    case CTA_TYPES.TRADE:
      return '/trade-vehicle'
    case CTA_TYPES.SOFT_PULL:
      return '/soft-pull'
    case CTA_TYPES.DEFAULT:
    default:
      return '/'
  }
}

const useSlideOutState = () => {
  const {showSlideOut, page} = useNavStateContext()
  const navDispatch = useNavDispatchContext()
  const [isOpen, setIsOpen] = useState(true)
  const location = useLocation()
  const history = useHistory()
  const previousCtaType = useRef<TCtaType | undefined>()
  const previousShowSlideOut = useRef<boolean | undefined>()
  const {ctaType} = useConfig()
  const {shouldPreventRedirects} = useFrameContext()

  // Opens if embed script sends message
  useEventListener('message', (event: MessageEvent) => {
    if (event.data?.name === WINDOW_IFRAME_OPEN_MESSAGE) {
      navDispatch({
        type: 'openSlideOut',
        payload: {
          page: getPageFromCtaType({ctaType: event.data?.ctaType}),
        },
      })
      setIsOpen(true)
    }
  })

  // Opens or closes based on nav state
  useEffect(() => {
    if (location.pathname === '/register' && document.cookie.indexOf('pseudo-auth-token=') !== -1) {
      history.push('/')
    }

    let transitionTimeout: ReturnType<typeof setTimeout> | undefined

    setIsOpen(showSlideOut)

    if (!showSlideOut) {
      transitionTimeout = setTimeout(() => {
        window.parent.postMessage(WINDOW_IFRAME_CLOSE_MESSAGE, '*')
      }, TRANSITION_DURATION)
    }

    return () => {
      clearTimeout(transitionTimeout)
    }
  }, [showSlideOut])

  useEffect(() => {
    if (!ctaType || (showSlideOut && previousShowSlideOut.current)) {
      return
    }

    previousShowSlideOut.current = showSlideOut

    if (
      !showSlideOut ||
      shouldPreventRedirects ||
      // If the path has changed -OR- the "ctaType" has changed, navigate to the new page.
      (location.pathname === page && previousCtaType.current === ctaType)
    ) {
      return
    }

    previousCtaType.current = ctaType
    history.push(page)
  }, [showSlideOut, page])

  return {isOpen}
}

export default useSlideOutState
