import {stringifyIncludes} from 'Src/apis/helpers'

type TPathFunction = (...args: (string | string[] | number | undefined)[]) => string

interface IRoute {
  base: string
  includes: Record<string, string>
  paths: Record<string, TPathFunction>
}

export const routes: Record<string, IRoute> = {
  analytics: {
    base: 'analytics/digital-retail',
    includes: {},
    paths: {
      event: (event) => `${routes.analytics.base}/${event}`,
    },
  },
  configs: {
    base: '/configs',
    includes: {},
    paths: {},
  },
  customers: {
    base: '/customers',
    includes: {},
    paths: {
      getDocuments: (leadId = '') => `${routes.customers.base}/documents?leadId=${leadId}`,
      uploadDocument: () => `${routes.customers.base}/documents`,
      deleteDocument: (documentId = '') => `${routes.customers.base}/documents/${documentId}`,
      leadsRequestTypes: () => `${routes.customers.base}/leads/request_types`,
    },
  },
  customerJourneys: {
    base: '/customer_journeys',
    includes: {},
    paths: {
      documents: (customerJourneyId) =>
        `${routes.customerJourneys.base}/${customerJourneyId}/documents`,
    },
  },
  dealerships: {
    base: '/dealerships',
    includes: {
      ANALYTICS_CONFIGURATION: 'analytics_configuration',
      CREDIT_APP_CONFIGURATION: 'credit_app_configuration',
      TRADE_VALUE_INTEGRATION: 'trade_value_integration',
      DR_SHARE_QUOTE: 'dr_share_quote',
      DR_SAVE_DEAL: 'dr_save_deal',
      DR_DOC_UPLOADS: 'dr_doc_uploads',
      DR_MIN_AMOUNT_FINANCED: 'dr_min_amount_financed',
    },
    paths: {
      dealership: (dealershipId, includes = []) => {
        return `${routes.dealerships.base}/${dealershipId}${stringifyIncludes(
          includes as string[]
        )}`
      },
    },
  },
  desking: {
    base: '/desking',
    includes: {},
    paths: {},
  },
  deskingLeadVehicles: {
    base: '/desking/leadVehicles',
    includes: {},
    paths: {
      leadVehicle: (leadVehicleId) => `${routes.deskingLeadVehicles.base}/${leadVehicleId}`,
    },
  },
  documents: {
    base: '/documents',
    includes: {},
    paths: {
      document: (documentId) => `${routes.documents.base}/${documentId}`,
    },
  },
  lang: {
    base: '/lang',
    includes: {},
    paths: {},
  },
  products: {
    base: '/products',
    includes: {},
    paths: {
      rates: () => `${routes.products.base}/rates`,
      ratesBatch: (id) => `${routes.products.base}/rates/batch/${id}`,
    },
  },
  register: {
    base: '/register',
    includes: {},
    paths: {
      verifyCode: () => `${routes.register.base}/verify-code`,
    },
  },
  sendVerification: {
    base: '/send-verification',
    includes: {},
    paths: {},
  },
  v1: {
    base: '/v1',
    includes: {},
    paths: {},
  },
}
