import React, {forwardRef} from 'react'
import classNames from 'classnames'

export const PRESETS = {
  primary: 'button button--primary tw-h-12',
  secondary: 'button button--secondary tw-h-12',
  get secondaryActive() {
    return classNames(this.secondary, 'button--active')
  },
  get secondaryInactive() {
    return classNames(this.secondary, 'button--inactive')
  },
  dangerPrimary: 'button button--primary button--danger tw-h-12',
  dangerSecondary: 'button button--secondary button--danger tw-h-12',
  link: 'button link',
} as const

interface IButton extends React.ComponentPropsWithoutRef<'button'> {
  preset?: keyof typeof PRESETS
}

const CtaButton = forwardRef<HTMLButtonElement, IButton>(
  ({type = 'button', preset = 'primary', className, ...props} = {}, ref): JSX.Element => (
    <button {...{ref, type, className: classNames(PRESETS[preset], className), ...props}} />
  )
)

export default CtaButton
