import React from 'react'
import Error from 'Src/components/PageComponents/Error'
import SlideOut from 'Src/components/SlideOut'
import useSlideOutState from 'Src/utilities/useSlideOutState'

const ErrorPage = () => {
  const {isOpen} = useSlideOutState()

  return (
    <div className="tw-h-full tw-w-full">
      <SlideOut open={isOpen}>
        <Error />
      </SlideOut>
    </div>
  )
}

export default ErrorPage
