import React, {createContext, useContext} from 'react'
import PropTypes from 'prop-types'
import {useRegistrationReducer} from '../reducers/RegistrationReducer'

export const RegistrationContext = createContext()

export const RegistrationContextProvider = ({children}) => {
  const [registrationState, registrationDispatch] = useRegistrationReducer()
  const contextValue = {registrationState, registrationDispatch}

  return (
    <RegistrationContext.Provider value={contextValue}>{children}</RegistrationContext.Provider>
  )
}

RegistrationContextProvider.propTypes = {
  children: PropTypes.element,
}

export const useRegistrationContext = () => useContext(RegistrationContext)
