export const CTA_TYPES = {
  DEFAULT: 'default',
  SOFT_PULL: 'softPull',
  TRADE: 'trade',
} as const

export const SOFT_PULL_STATUS = {
  LOADING: null,
  UNAVAILABLE: false,
} as const
