import PropTypes from 'prop-types'
import React, {useReducer, createContext, useContext} from 'react'
import DealReducer, {getInitialState} from '../reducers/DealReducer'

export const DealStateContext = createContext()
export const DealDispatchContext = createContext()

export const DealContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(DealReducer, getInitialState())

  return (
    <DealStateContext.Provider value={state}>
      <DealDispatchContext.Provider value={dispatch}>{children}</DealDispatchContext.Provider>
    </DealStateContext.Provider>
  )
}

export const useDealStateContext = () => useContext(DealStateContext)
export const useDealDispatchContext = () => useContext(DealDispatchContext)

DealContextProvider.propTypes = {
  children: PropTypes.element,
}
