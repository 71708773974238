import {useEffect, useRef} from 'react'
import structuredClone from 'core-js-pure/actual/structured-clone'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import {capitalize} from './textUtilities'
import {useFrameContext} from '../contexts/FrameContext'
import {useDealStateContext} from '../contexts/DealContext'
import {useSessionId} from './useSessionId'
import {routes} from '../apis/routes'

const shiftDataObject = {
  pageType: 'Digital Retailing', // Must be ('Digital Retailing')
  digRet: {
    dealId: null, // -----------------> '6664bab6-3062-47d4-b101-6edf6dc07ddb',
    priceUnlocked: null, // ----------> 'True', // Must be ('True','False')
    dealType: null, // ---------------> 'Finance', // Must be ('Finance', 'Lease', 'Cash')
    provider: 'A2ZSYNC', // ----------> 'DR Provider Name',
    addOnName: null, // --------------> 'Wheel locks', // only ondrAddtoCart event
    addOnType: null, // --------------> 'Accessories', // only ondrAddtoCart event. Must be ('FI', 'Accessories')
    depositMade: null, // ------------> 'True', // Must be ('True','False')
    depositAmt: null, // -------------> '2000',
    vehicleYear: null, // ------------> '2019',
    vehicleMake: null, // ------------> 'Volkswagen',
    vehicleModel: null, // -----------> 'DMC-12',
    vehicleVin: null, // -------------> '1V2UR2CA0KC602436',
    vehicleStatus: null, // ----------> 'New', // Must be ('New', 'Used', 'CPO')
    offerName: null, // --------------> '0% APR FINANCING', // only drOfferadded event
    offerType: null, // --------------> 'Finance', // only drOfferadded event. Must be ('Finance', 'Lease', 'Cash')
  },
}

// ====================shiftEvents ==============================================================================================================================
export const SHIFT_EVENTS = {
  IMPRESSION: 'drImpression', // ------------------------------> fire once per pageview
  INITIAL_CLICK: 'drInitialClick', // -------------------------> first click of session
  TRADE_IN_SHOW: 'drTradeInShown', // -------------------------> user views trade page
  TRADE_IN_START: 'drTradeInStart', // ------------------------> user starts trade process
  TRADE_IN_FINISH: 'drTradeInFinish', // ----------------------> user finishes trade
  PAYMENT_CALC_SHOWN: 'drPaymentCalcShown', // ----------------> user views payment calculator
  PAYMENT_CALC_INTERACTION: 'drPaymentCalcInteraction', // ----> when user first interacts with payment calc (ex. changes down payment)
  PAYMENT_CALC_FINISH: 'drPaymentCalcFinish', // --------------> fire event when user accepts payment terms and moves to next step
  LEAD_FORM_SHOWN: 'drLeadFormShown', // ----------------------> users sees customer info lead form
  LEAD_FORM_START: 'drLeadFormStart', // ----------------------> user first types into lead form
  LEAD_FORM_FINISH: 'drLeadFormFinish', // --------------------> user submits lead form
  CREDIT_APP_SHOWN: 'drCreditAppShown', // --------------------> user first sees credit app form (hard-pull)
  CREDIT_APP_START: 'drCreditAppStart', // --------------------> user first interacts with credit app form (hard-pull)
  CREDIT_APP_FINISH: 'drCreditAppFinish', // ------------------> user submits credit app form (hard-pull)
  DROP_SAVE: 'drDropSave', // ---------------------------------> user chooses to save progress and exit dr
  FI_SHOWN: 'drFiShown', // -----------------------------------> user is shown any non-physical add ons (protection / warrenties / services)
  FI_FINISH: 'drFiFinish', // ---------------------------------> user goes past non-physical add ons (protection / warrenties / services)
  ADD_TO_CART: 'drAddToCart', // ------------------------------> any time user adds item to cart (physical or non-physical)
  OFFER_ADDED: 'drOfferAdded', // -----------------------------> user adds an offer to their deal (Rebates)
  APPT_SCHED_SHOWN: 'drApptSchedShown', // --------------------> user first sees form for scheduling appointment / test drive ------------------------- Its a button not a Form
  APPT_SCHED_START: 'drApptSchedStart', // --------------------> user starts to interact with form to schedule appointment or test drive -------------- Its a button not a Form
  APPT_SCHED_FINISH: 'drApptSchedFinish', // ------------------> user finishes interacting with appointment/test drive form---------------------------- Its a button not a Form
  PRE_Q_SHOWN: 'drPreQShown', // ------------------------------> user first sees prequalification form (soft-pull)  ----------------------------------- NA
  PRE_Q_START: 'drPreQStart', // ------------------------------> user first interacts with prequalification form (soft-pull)  ------------------------- NA
  PRE_Q_FINSIH: 'drPreQFinish', // ----------------------------> user submits prequalification form (soft-pull)  -------------------------------------- NA
  ACCESSORIES_SHOWN: 'drAccessoriesShown', // -----------------> user is shown any physical add ons (accessories)  ------------------------------------ NA
  ACCESSORIES_FINISH: 'drAccessoriesFinish', // ---------------> user goes past physical add ons (accessories)  --------------------------------------- NA
  CONTRACT_SHOWN: 'drContractShown', // -----------------------> user is shown contract form for vehicle ---------------------------------------------- NA
  CONTRACT_START: 'drContractStart', // -----------------------> user interacts with contract form  --------------------------------------------------- NA
  CONTRACT_FINISH: 'drContractFinish', // ---------------------> user submits contract form  ---------------------------------------------------------- NA
}
// ==================================================================================================================================================================

export const ADD_ON_TYPES = {
  ACCESSORIES: 'Accessories',
  FI: 'FI',
}

// ==========================Mapper to convert Google Analytics events into Shift Events=============================================================================
// {
//     [gaAction]: {
//         matches: ['shiftEvent'] --------------------------> what shift events to fire for this given gaAction
//         locations: { -------------------------------------> list of locations to match on
//             [gaLocation] : {
//                 mactches: ['shiftEvent'] -----------------> what shift events to fire for this action and location combination
//                 exclude: {
//                     actionDetails: ['gaActionDetail'] ----> do not fire any events for this action and locaiton combination if gaActionDetail is in this list
//                 }
//             }
//         },
//         actionDetails: { ---------------------------------> list of actionDetails to match on
//             [gaActionDetail] : {
//                 mactches: ['shiftEvent'] -----------------> what shift events to fire for this action and actionDetail combination
//                 exclude: {
//                     locations: ['gaLocation'] ------------> do not fire any events for this action and actionDetail combination if gaLocation is in this list
//                 }
//             }
//         }
//     }
// }
// ==================================================================================================================================================================
const gaActionShiftEvents = {
  navigate: {
    matches: [SHIFT_EVENTS.IMPRESSION],
    locations: {
      desking: {
        matches: [SHIFT_EVENTS.PAYMENT_CALC_FINISH],
        exclude: {
          actionDetails: ['payment'],
        },
      },
      products: {
        matches: [SHIFT_EVENTS.FI_FINISH],
      },
    },
    actionDetails: {
      'is-credit-app': {
        matches: [SHIFT_EVENTS.CREDIT_APP_SHOWN],
      },
      schedule: {
        matches: [],
      },
      close: {
        matches: [SHIFT_EVENTS.DROP_SAVE],
      },
      'trade-experience': {
        matches: [SHIFT_EVENTS.TRADE_IN_SHOW],
      },
      logout: {
        matches: [SHIFT_EVENTS.DROP_SAVE],
      },
    },
  },
  change: {
    locations: {
      desking: {
        matches: [SHIFT_EVENTS.PAYMENT_CALC_INTERACTION],
        exclude: {
          actionDetails: [ACTION_DETAILS.PAGES.CREDIT],
        },
      },
    },
  },
  click: {
    actionDetails: {
      'individual credit app submitted': {
        matches: [SHIFT_EVENTS.CREDIT_APP_FINISH],
      },
    },
  },
}

const shiftEventToA2Zevent = {
  [SHIFT_EVENTS.INITIAL_CLICK]: 'initial-load',
  [SHIFT_EVENTS.PAYMENT_CALC_FINISH]: 'payment-calculation-finish',
}

const removeEmptyItems = (items) => {
  Object.keys(items).forEach((k) => Boolean(items[k]) === false && delete items[k])

  return items
}

const createDataObject = (vehicle, isPriceLocked = false, dealType, dealId) => {
  if (!vehicle) {
    return null
  }

  const baseObject = structuredClone(shiftDataObject)

  baseObject.digRet.dealId = dealId
  baseObject.digRet.dealType = capitalize(dealType)
  baseObject.digRet.vehicleMake = capitalize(vehicle.make)
  baseObject.digRet.vehicleModel = capitalize(vehicle.model)
  baseObject.digRet.vehicleVin = vehicle.vin
  baseObject.digRet.vehicleYear = vehicle.year?.toString()
  baseObject.digRet.vehicleStatus = capitalize(vehicle.type)
  baseObject.digRet.priceUnlocked = capitalize((!isPriceLocked).toString())
  baseObject.digRet = removeEmptyItems(baseObject.digRet)

  return baseObject
}

const sendShiftEvent = (event) => {
  if (event === SHIFT_EVENTS.INITIAL_CLICK) {
    window.sd('send', 'pageview')

    return
  }

  window.sd('send')
}

const sendA2ZEvent = (api, event) => {
  const a2zEvent = shiftEventToA2Zevent?.[event]

  if (a2zEvent) {
    api.post(routes.analytics.paths.event(a2zEvent))
  }
}

const sendEvents = (api, shiftEvents, data) => {
  if (window.sd === undefined) {
    return
  }

  shiftEvents.forEach((event) => {
    window.sd('dataLayer', {...data, events: event})
    sendShiftEvent(event)
    sendA2ZEvent(api, event)
  })
}

export const useShiftEvents = () => {
  const {vehicleData: vehicle, isPriceLocked, a2zApi} = useFrameContext() || {}
  const {dealType} = useDealStateContext() || {}
  const {sessionId: dealId} = useSessionId()
  const newDataObject = createDataObject(vehicle, isPriceLocked, dealType, dealId)

  const emptyReturnFunction = () => undefined

  if (!newDataObject) {
    // returning empty functions here so that we don't make a call to track events when we don't have the data
    return {
      trackGAEventAsShiftEvent: emptyReturnFunction,
      trackShiftEvents: emptyReturnFunction,
    }
  }

  const trackGAEventAsShiftEvent = (
    {action, actionDetail, location},
    dataObject = newDataObject
  ) => {
    const shiftAction = gaActionShiftEvents[action]

    const isShiftActionDetailExcludedFromLocations = Boolean(
      shiftAction?.locations?.[location]?.exclude?.actionDetails?.includes(actionDetail)
    )
    const isShiftLocationExcludedFromActionDetails = Boolean(
      shiftAction?.actionDetails?.[actionDetail]?.exclude?.locations?.includes(location)
    )

    const shiftActionMatches = shiftAction?.matches || []
    let shiftActionDetailsMatches = shiftAction?.actionDetails?.[actionDetail]?.matches || []
    let shiftLocationsMatches = shiftAction?.locations?.[location]?.matches || []

    if (isShiftActionDetailExcludedFromLocations) {
      shiftLocationsMatches = []
    }

    if (isShiftLocationExcludedFromActionDetails) {
      shiftActionDetailsMatches = []
    }

    const matches = [...shiftActionMatches, ...shiftActionDetailsMatches, ...shiftLocationsMatches]

    if (matches.length && dataObject?.digRet?.vehicleVin) {
      sendEvents(a2zApi, matches, dataObject)
    }
  }

  const trackShiftEvents = (events, additionalData, dataObject = newDataObject) => {
    dataObject.digRet = {...dataObject.digRet, ...additionalData}

    if (events.length && dataObject?.digRet?.vehicleVin) {
      sendEvents(a2zApi, events, dataObject)
    }
  }

  return {trackGAEventAsShiftEvent, trackShiftEvents}
}

export const useTrackShiftEvents = (event, additionalData) => {
  const {trackShiftEvents} = useShiftEvents()
  const calledOnce = useRef(false)

  useEffect(() => {
    if (calledOnce.current || !event) {
      return
    }

    calledOnce.current = true
    trackShiftEvents(event, additionalData)
  }, [event])
}
