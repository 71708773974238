import {useEffect, useRef} from 'react'

const useEventListener = (type, handler, target = null) => {
  // Use handler ref to prevent running effect every render
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    target = target || window
    const listener = target.addEventListener(type, savedHandler.current)

    return () => {
      target.removeEventListener(type, listener)
    }
  }, [type, savedHandler, target])
}

export default useEventListener
