import React, {createContext, useContext, useState} from 'react'
import PropTypes from 'prop-types'

export const ProductContext = createContext(null)

export const ProductContextProvider = ({children}) => {
  const [cashProducts, setCashProducts] = useState(null)
  const [financeProducts, setFinanceProducts] = useState(null)
  const [leaseProducts, setLeaseProducts] = useState(null)

  const [cashRatedProducts, setCashRatedProducts] = useState([])
  const [financeRatedProducts, setFinanceRatedProducts] = useState([])
  const [leaseRatedProducts, setLeaseRatedProducts] = useState([])

  const [productRates, setProductRates] = useState(null)

  const [ratingDealState, setRatingDealState] = useState({})
  const [selectedProducts, setSelectedProducts] = useState({
    cash: new Set(),
    finance: new Set(),
    lease: new Set(),
  })

  const [productFilters, setProductFilters] = useState(new Map())

  const getRatedProductsByDealType = (dealType) => {
    switch (dealType) {
      case 'cash':
        return cashRatedProducts
      case 'finance':
        return financeRatedProducts
      case 'lease':
        return leaseRatedProducts
      default:
        return null
    }
  }

  const setRatedProductsByDealType = (dealType, products = []) => {
    if (!Array.isArray(products)) {
      return
    }

    const productsCopy = [...products]

    switch (dealType) {
      case 'cash':
        setCashRatedProducts(productsCopy)
        break
      case 'finance':
        setFinanceRatedProducts(productsCopy)
        break
      case 'lease':
        setLeaseRatedProducts(productsCopy)
        break
      default:
    }
  }

  const resetProducts = () => {
    setCashProducts(null)
    setFinanceProducts(null)
    setLeaseProducts(null)

    setCashRatedProducts([])
    setFinanceRatedProducts([])
    setLeaseRatedProducts([])

    setRatingDealState({})
    setSelectedProducts({
      cash: new Set(),
      finance: new Set(),
      lease: new Set(),
    })
  }

  const toggleProductSelection = (dealType, productId) => {
    if (!productId || !dealType) {
      return
    }

    const tempSelected = {...selectedProducts}

    if (!tempSelected[dealType].delete(productId)) {
      tempSelected[dealType] = new Set([...selectedProducts[dealType], productId])
    }

    setSelectedProducts(tempSelected)
  }

  const selectProduct = (productId, dealType) => {
    if (!productId || !dealType) {
      return
    }

    selectedProducts[dealType]?.add(productId)
  }

  const getRatedProduct = (id, dealType) =>
    getRatedProductsByDealType(dealType)?.find((rate) => rate.productId === id)

  const getProductsByDealType = (dealType) => {
    switch (dealType) {
      case 'cash':
        return cashProducts
      case 'finance':
        return financeProducts
      case 'lease':
        return leaseProducts
      default:
        return null
    }
  }

  const getProduct = (id, dealType) =>
    getProductsByDealType(dealType)?.find((product) => product.id === id)

  const setProductsByDealType = (dealType, products = []) => {
    if (!Array.isArray(products)) {
      return
    }

    const productsCopy = [...products]

    switch (dealType) {
      case 'cash':
        setCashProducts(productsCopy)
        break
      case 'finance':
        setFinanceProducts(productsCopy)
        break
      case 'lease':
        setLeaseProducts(productsCopy)
        break
      default:
    }
  }

  const getSelectedRatedProducts = (dealType, includeProductsWithoutPrices = false) => {
    if (!dealType || selectedProducts[dealType].size < 1) {
      return []
    }

    const selectedRatedProducts = []

    selectedProducts[dealType].forEach((id) => {
      const ratedProduct = getRatedProduct(id, dealType)

      if (includeProductsWithoutPrices || ratedProduct?.rate?.id) {
        const product = getProduct(id, dealType)

        selectedRatedProducts.push({
          category: product?.product_categories?.name ?? 'No product category name',
          cost: ratedProduct?.rate?.dealer_cost,
          miles: ratedProduct?.miles ?? 0,
          name: ratedProduct?.name,
          price: ratedProduct?.price ?? 0,
          product_id: id,
          uid: ratedProduct?.rate?.id ?? 0,
          term: ratedProduct?.term ?? 0,
        })
      }
    })

    return selectedRatedProducts
  }

  const assignProductFilter = (productId, filters) => {
    const productFilter = productFilters.get(productId)

    if (JSON.stringify(productFilter) === JSON.stringify(filters)) {
      return
    }

    setProductFilters(new Map(productFilters.set(productId, filters)))
  }

  const isEverySelectedProductRated = () => {
    return [
      ...selectedProducts.cash,
      ...selectedProducts.finance,
      ...selectedProducts.lease,
    ].every((productId) =>
      [...cashRatedProducts, ...financeRatedProducts, ...leaseRatedProducts].find(
        (rate) => productId === rate.productId
      )
    )
  }

  const hasFetchedProducts =
    cashProducts !== null && financeProducts !== null && leaseProducts !== null
  const hasProducts = !!cashProducts?.length || !!financeProducts?.length || !!leaseProducts?.length

  return (
    <ProductContext.Provider
      value={{
        assignProductFilter,
        cashRatedProducts,
        financeRatedProducts,
        getProductsByDealType,
        getRatedProduct,
        getRatedProductsByDealType,
        getSelectedRatedProducts,
        hasFetchedProducts,
        hasProducts,
        isEverySelectedProductRated,
        leaseRatedProducts,
        productFilters,
        productRates,
        ratingDealState,
        resetProducts,
        selectProduct,
        selectedProducts,
        setProductRates,
        setProductsByDealType,
        setRatedProductsByDealType,
        setRatingDealState,
        toggleProductSelection,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

ProductContextProvider.propTypes = {
  children: PropTypes.node,
}

/**
 * @returns {assignProductFilter, cashRatedProducts, financeRatedProducts, getProductsByDealType, getRatedProduct, getRatedProductsByDealType, getSelectedRatedProducts, hasFetchedProducts, hasProducts, isEverySelectedProductRated, leaseRatedProducts, productFilters, productRates, ratingDealState, resetProducts, selectProduct, selectedProducts, setProductRates, setProductsByDealType, setRatedProductsByDealType, setRatingDealState, toggleProductSelection}
 */
export const useProductContext = () => useContext(ProductContext)
