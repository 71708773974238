import PropTypes from 'prop-types'
import React, {useReducer, createContext, useContext} from 'react'
import NavReducer, {initialNavState} from '../reducers/NavReducer'

export const NavStateContext = createContext()
export const NavDispatchContext = createContext()

export const NavContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(NavReducer, initialNavState)

  return (
    <NavStateContext.Provider value={state}>
      <NavDispatchContext.Provider value={dispatch}>{children}</NavDispatchContext.Provider>
    </NavStateContext.Provider>
  )
}

export const useNavStateContext = () => useContext(NavStateContext)

/**
 * Custom hook to access the navigation dispatch context
 * @returns {Dispatch<{ type: string; payload: string }>} The navigation dispatch context
 */
export const useNavDispatchContext = () => useContext(NavDispatchContext)

NavContextProvider.propTypes = {
  children: PropTypes.element,
}
