/* eslint-disable no-unused-expressions */
const loadShiftAnalyticsScript = (serverUrl) => {
  // eslint-disable-next-line padding-line-between-statements
  ;(function (i, s, o, g, r, a, m) {
    i['ShiftAnalyticsObject'] = r
    ;(i[r] =
      i[r] ||
      function () {
        ;(i[r].q = i[r].q || []).push(arguments)
      }),
      (i[r].l = 1 * new Date())
    ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', serverUrl, 'sd')
}

export const initalizeShiftAnalytics = (shiftData) => {
  const {enabled, serverUrl, clientId, retailerId, providerId} = shiftData || {}

  if (enabled && serverUrl && clientId && retailerId && providerId) {
    loadShiftAnalyticsScript(serverUrl)
    // eslint-disable-next-line no-undef
    sd('create', clientId, retailerId, providerId)
  }
}
