import PropTypes from 'prop-types'
import React, {useReducer, createContext, useContext} from 'react'
import EmailQuoteReducer, {initialState} from '../reducers/EmailQuoteReducer'

export const EmailQuoteStateContext = createContext()
export const EmailQuoteDispatchContext = createContext()

export const EmailQuoteContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(EmailQuoteReducer, initialState)

  return (
    <EmailQuoteStateContext.Provider value={state}>
      <EmailQuoteDispatchContext.Provider value={dispatch}>
        {children}
      </EmailQuoteDispatchContext.Provider>
    </EmailQuoteStateContext.Provider>
  )
}

export const useEmailQuoteStateContext = () => useContext(EmailQuoteStateContext)
export const useEmailQuoteDispatchContext = () => useContext(EmailQuoteDispatchContext)

EmailQuoteContextProvider.propTypes = {
  children: PropTypes.element,
}
