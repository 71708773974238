import * as yup from 'yup'

export const ConfigSchema = yup.object().shape({
  client: yup.object().shape({
    uuid: yup.string().required(),
  }),
  dealership: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().nullable().notRequired(),
    email: yup.string().nullable().notRequired(),
    phone: yup.string().nullable().notRequired(),
    isPriceLocked: yup.boolean().required(),
  }),
  api: yup.object().shape({
    token: yup.string().required(),
    url: yup.string().required(),
  }),
  useCache: yup.boolean().required(),
  vehicle: yup.object().shape({
    vin: yup.string().required(),
  }),
  analytics: yup.object().notRequired().nullable().shape({
    gtmId: yup.string().notRequired().nullable(),
    // eslint-disable-next-line react/forbid-prop-types
    measurementIds: yup.array().notRequired().nullable(),
  }),
})
