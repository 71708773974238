import React from 'react'
import CtaButton from 'Src/components/Buttons/CtaButton'
import {useNavDispatchContext} from 'Src/contexts/NavContext'
import {FindQuery, trans} from 'Src/utilities/Helpers'

const ErrorContainer = () => {
  const defaultMessage = trans('Please try again later.')
  const navDispatch = useNavDispatchContext()
  const message = FindQuery('message') ?? defaultMessage

  const handleCloseButtonClick = () => navDispatch({type: 'closeSlideOut'})

  return (
    <div className="error-bkgd tw-h-screen tw-w-full">
      <div className="error-page tw-px-10">
        <div className="tw-flex tw-justify-center">
          <h2 className="whoops">{trans('Whoops')}</h2>
        </div>
        <div className="tw-mb-6 tw-flex tw-justify-center">
          <div data-test="messageDiv" id="error-msg" className="error-msg">
            {message}
          </div>
        </div>
        <div className="tw-mb-5 tw-flex tw-justify-center md:tw-mb-0">
          <CtaButton
            className="tw-w-full md:tw-max-w-md"
            onClick={handleCloseButtonClick}
            data-test="button"
          >
            {trans('Close')}
          </CtaButton>
        </div>
      </div>
    </div>
  )
}

export default ErrorContainer
