import {IDeskingResponse} from 'Src/utilities/DeskingTypes'
import {IDealState} from 'Src/utilities/DealContextTypes'
import {
  IPaymentCalculation,
  IPaymentCalculationDealPresenter,
  IPaymentCalculationInfo,
} from 'Src/utilities/PaymentCalculationTypes'
import {ICustomerCityCounty} from 'Src/utilities/CustomerContextTypes'
import {DealTypes} from 'Src/utilities/PaymentCalculationConstants'
import {ILeadVehicle} from 'Src/utilities/LeadVehicleTypes'
import _ from 'lodash'
import {IRebateValue} from 'Src/utilities/MarketScanTypes'
import {parseCurrentData} from 'Src/utilities/ProgramHelpers'

const parseAppliedRebateIds = (rebateValues?: Array<IRebateValue>): string[] => {
  if (!rebateValues) {
    return []
  }

  return rebateValues
    .filter((rebate) => rebate.RebateID && rebate.Value)
    .map((rebate) => rebate.RebateID)
}

const getAppliedRebateIds = (
  programInfo: IPaymentCalculationInfo,
  dealPresenter: IPaymentCalculationDealPresenter
) => {
  const {LenderName: lenderName, RebateValues: rebateValues} = programInfo
  const {chosen_rebates_generic: chosenRebatesGeneric} = dealPresenter

  if (lenderName) {
    return parseAppliedRebateIds(rebateValues)
  }

  const chosenRebates = chosenRebatesGeneric ? JSON.parse(chosenRebatesGeneric) : null

  if (chosenRebates?.selectedRebates?.length) {
    return chosenRebates.selectedRebates
  }

  return []
}

export const getDealStateFromExistingQuote = (
  paymentCalculation: IPaymentCalculation,
  deskingResponseData: IDeskingResponse,
  currentDealState: IDealState
): IDealState => {
  const {deal_presenter: dealPresenter, info: programInfo} = paymentCalculation
  const {
    credit_score: creditScore,
    deal_type: dealType,
    cash_down: retailDownPayment,
    down_payment_from_cash: leaseDownPayment,
    term,
    annual_mileage: annualMileage,
    zip_code: zipCode,
  } = dealPresenter
  const selectedRebateIds: string[] = getAppliedRebateIds(programInfo, dealPresenter)
  const updatedDealState: Partial<IDealState> = {
    creditScore: creditScore.toString(),
    data: deskingResponseData,
    dealType,
    selectedRebateIds,
    zipCode,
  }

  if (dealType === DealTypes.LEASE) {
    updatedDealState.leaseDownPayment = leaseDownPayment
    updatedDealState.leaseTerm = term
    updatedDealState.selectedLeaseMileageOption = annualMileage.toString(10)
  }

  if (dealType === DealTypes.FINANCE) {
    updatedDealState.retailTerm = term
    updatedDealState.retailDownPayment = retailDownPayment
  }

  return {
    ...currentDealState,
    ...updatedDealState,
  }
}

export const selectProductsFromExistingQuote = (
  paymentCalculation: IPaymentCalculation,
  selectProduct: (productId: string, dealType: string) => void
): void => {
  const {deal_presenter: dealPresenter} = paymentCalculation
  const {products, deal_type: dealType} = dealPresenter

  if (products?.length) {
    products.forEach((product) => {
      selectProduct(product.product_id, dealType)
    })
  }
}

export const getUpdatedRegionFromExistingQuote = (
  paymentCalculation: IPaymentCalculation,
  customerCityCounty: ICustomerCityCounty
): {zipCode: string; cityCounty: {id: number; inCity: boolean}} | null => {
  const {deal_presenter: dealPresenter} = paymentCalculation
  const {zip_code: zipCode, taxParams} = dealPresenter
  const calculationRegionId = Number(taxParams.region_id)
  const calculationInCity = !!taxParams.in_city

  if (
    Number(customerCityCounty.id) !== calculationRegionId ||
    !!customerCityCounty.inCity !== calculationInCity
  ) {
    return {
      zipCode,
      cityCounty: {
        id: calculationRegionId,
        inCity: calculationInCity,
      },
    }
  }

  return null
}

export const isPreviouslySavedQuoteEqualToCurrent = (
  previouslySavedQuote: ILeadVehicle | null,
  currentDealState: IDealState,
  customerCityCounty: ICustomerCityCounty,
  getSelectedRatedProducts: (
    dealType: string,
    includeProductsWithoutPrices: boolean
  ) => {product_id: string}[]
): boolean => {
  if (!previouslySavedQuote) {
    return false
  }

  const {
    calculation: {deal_presenter: dealPresenter, info: programInfo},
  } = previouslySavedQuote

  const {
    credit_score: creditScore,
    deal_type: dealType,
    cash_down: retailDownPayment,
    down_payment_from_cash: leaseDownPayment,
    term,
    annual_mileage: annualMileage,
    zip_code: zipCode,
    taxParams,
  } = dealPresenter

  const previouslyAppliedRebateIds: string[] = getAppliedRebateIds(programInfo, dealPresenter)

  const {
    currentProgram: {appliedRebatesIds},
  } = parseCurrentData(currentDealState)

  const cashDownPrev = dealType === 'finance' ? retailDownPayment : leaseDownPayment
  const cashDownCurr =
    currentDealState.dealType === 'finance'
      ? currentDealState.retailDownPayment
      : currentDealState.leaseDownPayment

  const currentTerm =
    dealType === 'finance' ? currentDealState.retailTerm : currentDealState.leaseTerm

  const calculationRegionId = Number(taxParams.region_id)
  const calculationInCity = !!taxParams.in_city

  const isRegionCityEqual =
    Number(customerCityCounty.id) === calculationRegionId &&
    !!customerCityCounty.inCity === calculationInCity

  const isProductsEqual = _.isEqual(
    dealPresenter.products,
    getSelectedRatedProducts(dealType, true)?.map((product) => {
      return {
        product_id: product.product_id,
      }
    })
  )
  const isRebatesEqual = _.xor(previouslyAppliedRebateIds, appliedRebatesIds).length === 0

  const isLeaseMileageEqual =
    dealType !== 'lease' ||
    annualMileage?.toString(10) === currentDealState.selectedLeaseMileageOption

  const isPreviousEqualToCurrent =
    String(creditScore) === currentDealState.creditScore &&
    dealType === currentDealState.dealType &&
    cashDownPrev === cashDownCurr &&
    isLeaseMileageEqual &&
    isProductsEqual &&
    isRebatesEqual &&
    term === currentTerm &&
    zipCode === currentDealState.zipCode &&
    isRegionCityEqual

  return isPreviousEqualToCurrent
}
